<template>
  <div class="header">
    <div class="header_top" v-if="screenWidth > 750">
      <div class="header_top_wrap">
        <div>您好，欢迎访问新征程（天津）供应链管理集团有限公司网站！</div>
        <div>
          <div @click="handleCollect">加入收藏</div>
          <div></div>
          <div @click="handleContact">联系我们</div>
        </div>
      </div>
    </div>
    <div class="company_name" v-if="screenWidth > 750">
      <div class="logo">
        <img src="../../assets/image/logo.png" alt="" />
      </div>
      <div class="name">新征程（天津）供应链管理集团有限公司</div>
      <div class="contact">
        <img src="../../assets/image/24phone.png" alt="" />
        <span></span>
        <div>
          <div>022-25789782</div>
          <div>热线电话</div>
        </div>
      </div>
    </div>
    <div class="nav_list">
      <div class="nav_content" v-if="screenWidth > 750">
        <!-- <template v-for="item in menus" :key="item.id">
          <div
            class="nav_item"
            :class="{ active: currentMenu === item.name }"
            @click="handleChangeNav(item)"
          >
            {{ item.title }}
          </div>
        </template> -->
        <a-menu
          v-model:selectedKeys="current"
          mode="horizontal"
          @click="handleMenu"
        >
          <template v-for="item in menus" :key="item.key">
            <template v-if="item.type === 'menuItem'">
              <a-menu-item :key="item.key">
                {{ item.label }}
              </a-menu-item>
            </template>
            <template v-else>
              <a-sub-menu :key="item.key">
                <template #title>{{ item.title }}</template>
                <template
                  v-for="(el, index) in item.children"
                  :key="el.type + index"
                >
                  <template v-for="element in el.children" :key="element.key">
                    <a-menu-item>{{ element.label }}</a-menu-item>
                  </template>
                </template>
              </a-sub-menu>
            </template>
          </template>
        </a-menu>
        <a-menu
          v-model:selectedKeys="currentMenu"
          mode="horizontal"
          :items="menus"
        />
      </div>
      <div class="nav_content" style="padding: 1rem 0" v-else>
        <!-- <a-select
          :bordered="false"
          :options="menus"
          v-model:value="currentMenu"
          :fieldNames="fieldNames"
          style="width: 100%"
          @change="handleChange"
        ></a-select> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "headerView",
};
</script>
<script setup>
import {
  ref,
  reactive,
  computed,
  onMounted,
  onBeforeUnmount,
  watch,
} from "vue";
import { useStore } from "vuex";
import router from "@/router";
import { useRoute } from "vue-router";
const store = useStore();
const route = useRoute();
let screenWidth = ref(window.innerWidth);
let fieldNames = {
  label: "title",
  value: "name",
};
const currentMenu = computed(() => {
  return store.state.currentMenu; // 假设 Vuex 中有一个名为 count 的状态
});

const current = ref(currentMenu.value);

let menus = reactive([
  {
    key: "home",
    label: "首页",
    title: "首页",
    type: "menuItem",
  },
  {
    key: "groupOverview",
    label: "集团概况",
    title: "集团概况",
    type: "subMenu",
    children: [
      {
        type: "group",
        children: [
          {
            label: "新征程集团公司",
            key: "xin",
          },
          {
            label: "绿色之窗",
            key: "lv",
          },
          {
            label: "中运华腾",
            key: "zhong",
          },
          {
            label: "初心能源",
            key: "chu",
          },
          {
            label: "嘉运通",
            key: "jia",
          },
        ],
      },
    ],
  },
  {
    key: "casePresentation",
    label: "案例展示",
    title: "案例展示",
    type: "subMenu",
    children: [
      {
        type: "group",
        children: [
          {
            label: "铁矿石贸易",
            key: "tiekong",
          },
          {
            label: "散货船舶运输",
            key: "sanhuo",
          },
          {
            label: "乌兰巴托矿山机械",
            key: "wulan",
          },
          {
            label: "重庆风电",
            key: "chongqing",
          },
          {
            label: "霍尔果斯项目",
            key: "huoerguo",
          },
        ],
      },
    ],
  },
  {
    key: "productIntroduction",
    label: "产品介绍",
    title: "产品介绍",
    type: "subMenu",
    children: [
      {
        type: "group",
        children: [
          {
            label: "铁矿石",
            key: "tiekuang",
          },
          {
            label: "铜精粉",
            key: "tongjing",
          },
          {
            label: "铅锌混合粉",
            key: "qianxin",
          },
          {
            label: "硫精粉",
            key: "liujingfen",
          },
          {
            label: "高钙石",
            key: "gaogaishi",
          },
          {
            label: "铁精粉",
            key: "tiejingfen",
          },
        ],
      },
    ],
  },
  {
    key: "cooperation",
    label: "商业合作",
    title: "商业合作",
    type: "menuItem",
  },
  {
    key: "joinUs",
    label: "诚聘英才",
    title: "诚聘英才",
    type: "menuItem",
  },
  {
    key: "contactUs",
    label: "联系我们",
    title: "联系我们",
    type: "menuItem",
  },
]);
let handleMenu = (e) => {
  let path = "";
  let arr = e.keyPath;
  if (
    arr[0] == "casePresentation" ||
    arr[0] == "productIntroduction" ||
    arr[0] == "groupOverview"
  ) {
    path = `${arr[0]}?name=${arr[1]}`;
  } else {
    path = arr[0] == "home" ? "" : arr[0];
  }
  router.push("/" + path);
};
let handleChangeNav = (item) => {
  let path = item.name == "home" ? "" : item.name;
  router.push("/" + path);
};
let handleChange = (e) => {
  // console.log(item);
  handleChangeNav({ name: e });
};
const updateScreenWidth = () => {
  screenWidth.value = window.innerWidth;
};
let handleRouteQuery = () => {
  current.value = currentMenu.value;
};
let handleCollect = () => {
  if (window.external && "AddFavorite" in window.external) {
    window.external.AddFavorite(window.location.href, document.title);
  } else {
    alert("您的浏览器不支持此功能，请使用Ctrl+D进行添加");
  }
};
let handleContact = () => {
  router.push("/contactUs");
};
// wath-route
watch(() => route.query, handleRouteQuery);

onMounted(() => {
  window.addEventListener("resize", updateScreenWidth);
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", updateScreenWidth);
});
</script>
<style lang="less" scoped>
:deep(.ant-menu) {
  background: none;
  border: none;
  &.ant-menu-horizontal {
    line-height: 60px !important;
  }
  .ant-menu-item {
    min-width: 7em;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ant-menu-item,
  .ant-menu-submenu {
    color: #fff;
    transition: all 0.5s;
  }
  .ant-menu-item-active,
  .ant-menu-item-selected,
  .ant-menu-submenu-selected,
  .ant-menu-submenu-active {
    border: none !important;
    background: #0185d0 !important;
    color: #fff !important;
    .ant-menu-submenu-title {
      color: #fff !important;
      // .ant-menu-title-content {
      //   color: #fff !important;
      // }
    }
  }
}
:deep(.ant-select) {
  .ant-select-selection-item {
    text-align: center;
    color: #fff;
    font-size: 1.6rem;
  }
  .ant-select-arrow {
    color: #fff !important;
    right: 3rem;
    font-size: 2rem;
  }
}
.header {
  width: 100%;
  overflow: hidden;
  .header_top {
    width: 100%;
    background: #f2f2f2;
    .header_top_wrap {
      max-width: 1200px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #333333;
      font-size: 1.4rem;
      line-height: 2.5em;
      & > div {
        &:nth-child(2) {
          display: flex;
          align-items: center;
          & > div {
            cursor: pointer;
            &:nth-child(2) {
              height: 1.4rem;
              width: 0.1rem;
              background: #666;
              margin: 0 1rem;
            }
          }
        }
      }
    }
  }
  .company_name {
    width: 100%;
    display: flex;
    align-items: center;
    max-width: 1200px;
    min-width: 1000px;
    overflow: auto;
    margin: 0 auto;
    padding: 2rem 0;
    .logo {
      margin-right: 2rem;
    }
    .name {
      font-size: 2.4rem;
    }
    .contact {
      margin-left: 2rem;
      display: flex;
      align-items: center;
      img {
        width: 6rem;
      }
      & > div {
        margin-left: 1rem;
        font-size: 2rem;
        font-weight: bold;
        color: #0185d0;
        line-height: normal !important;
      }
    }
  }
  .nav_list {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: #1048a0;
    overflow: hidden;
    .nav_content {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      .nav_item {
        font-size: 1.6rem;
        color: #fff;
        padding: 1.5rem 0;
        min-width: 10rem;
        text-align: center;
        background: transparent;
        transition: all 0.5s ease;
        cursor: pointer;
        border-right: 1px solid #0b2d7e;
        &:nth-child(1) {
          border-left: 1px solid #0b2d7e;
        }
        &:hover {
          background: #0185d0;
        }
        &.active {
          background: #0185d0;
        }
      }
    }
  }
}
</style>
