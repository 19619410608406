<template>
  <div class="footer" ref="footerContainer">
    <div class="footer_content">
      <div class="footer_l">
        <div class="company_name">新征程（天津）供应链管理集团有限公司</div>
        <div>地址：天津市滨海新区响螺湾富力广东大厦A座34层</div>
        <div>电话：022-25789782</div>
        <div>传真：022-25795721</div>
        <div>邮箱: newjourney_gcltd@126.com</div>
        <div>网址：https://www.newjourneytj.com/</div>
      </div>
      <div class="footer_r">
        <template v-for="item in footerNav" :key="item.id">
          <div class="footer_t_item">
            <div @click="goDetail(item.url)">{{ item.title }}</div>
            <div class="footer_t_item_children" v-if="item.children">
              <template v-for="(el, i) in item.children" :key="i + el.key">
                <div @click="goDetail(el.url)">{{ el.label }}</div>
              </template>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "footerView",
};
</script>
<script setup>
import { ref, reactive } from "vue";
import { useStore } from "vuex";
import router from "@/router";
const store = useStore();
const footerContainer = ref(null);
let footerNav = reactive([
  {
    id: "footer1",
    title: "集团概况",
    url: "/groupOverview?name=xin",
    children: [
      {
        label: "新征程集团公司",
        key: "xin",
        url: "/groupOverview?name=xin",
      },
      {
        label: "绿色之窗",
        key: "lv",
        url: "/groupOverview?name=lv",
      },
      {
        label: "中运华腾",
        key: "zhong",
        url: "/groupOverview?name=zhong",
      },
      {
        label: "初心能源",
        key: "chu",
        url: "/groupOverview?name=chu",
      },
      {
        label: "嘉运通",
        key: "jia",
        url: "/groupOverview?name=jia",
      },
    ],
  },
  {
    id: "footer2",
    title: "案例",
    url: "/casePresentation?name=chongqing",
    children: [
      {
        label: "铁矿石贸易",
        key: "tiekong",
        url: "/casePresentation?name=tiekong",
      },
      {
        label: "散货船舶运输",
        key: "sanhuo",
        url: "/casePresentation?name=sanhuo",
      },
      {
        label: "乌兰巴托矿山机械",
        key: "wulan",
        url: "/casePresentation?name=wulan",
      },
      {
        label: "重庆风电",
        key: "chongqing",
        url: "/casePresentation?name=chongqing",
      },
      {
        label: "霍尔果斯项目",
        key: "huoerguo",
        url: "/casePresentation?name=huoerguo",
      },
    ],
  },
  {
    id: "footer3",
    title: "产品",
    url: "/productIntroduction?name=gaogaishi",
    children: [
      {
        label: "铁矿石",
        key: "tiekuang",
        url: "/productIntroduction?name=tiekuang",
      },
      {
        label: "铜精粉",
        key: "tongjing",
        url: "/productIntroduction?name=tongjing",
      },
      {
        label: "铅锌混合粉",
        key: "qianxin",
        url: "/productIntroduction?name=qianxin",
      },
      {
        label: "硫精粉",
        key: "liujingfen",
        url: "/productIntroduction?name=liujingfen",
      },
      {
        label: "高钙石",
        key: "gaogaishi",
        url: "/productIntroduction?name=gaogaishi",
      },
      {
        label: "铁精粉",
        key: "tiejingfen",
        url: "/productIntroduction?name=tiejingfen",
      },
    ],
  },
  {
    id: "footer4",
    title: "商业合作",
    url: "/cooperation",
  },
  {
    id: "footer5",
    title: "诚聘英才",
    url: "/joinUs",
  },
  {
    id: "footer6",
    title: "联系我们",
    url: "/contactUs",
  },
]);
let goDetail = (url) => {
  router.push(url);
};
</script>
<style lang="less" scoped>
@media screen and (max-width: 750px) {
  .footer_l {
    font-size: 1.6rem !important;
    & > div {
      display: flex;
      justify-content: center;
    }
  }
  .footer_r {
    display: none !important;
  }
}

@media screen and (max-width: 1050px) {
  .footer {
    .footer_content {
      flex-direction: column;
      .footer_l {
        margin-bottom: 2rem;
      }
      .footer_r {
        justify-content: flex-end;
        margin: 0 !important;
        padding: 0 !important;
      }
    }
  }
}
.footer {
  padding: 3rem 0 2rem;
  background: #191919;
  border-top: 1px solid #434343;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  .footer_content {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 0 2rem;
    .footer_l {
      flex-shrink: 0;
      color: #fff;
      font-size: 1.4rem;
      & > div {
        line-height: 2em;
        &.company_name {
          font-weight: bold;
        }
      }
    }
    .footer_r {
      flex-shrink: 0;
      flex: 1;
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      box-sizing: border-box;
      padding-right: 4rem;
      .footer_t_item {
        padding-right: 2em;
        & > div:nth-child(1) {
          color: #fff;
          font-size: 1.8rem;
        }
        .footer_t_item_children {
          color: #ccc;
          font-size: 1.4rem;
          line-height: 2em;
        }
      }
    }
  }
}
</style>
