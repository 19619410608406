import { createRouter, createWebHistory } from "vue-router";
import layout from "../components/layout/index.vue";
import store from "../store";

const routes = [
  {
    path: "/",
    name: "home",
    component: layout,
    children: [
      {
        path: "",
        name: "home",
        component: () => import("../views/HomeView.vue"),
        meta: {
          title: "首页",
        },
      },
      {
        path: "groupOverview",
        name: "groupOverview",
        component: () => import("../views/GroupOverview.vue"),
        meta: {
          title: "集团概况",
        },
      },
      {
        path: "casePresentation",
        name: "casePresentation",
        component: () => import("../views/CasePresentation.vue"),
        meta: {
          title: "案例展示",
        },
      },
      {
        path: "productIntroduction",
        name: "productIntroduction",
        component: () => import("../views/ProductIntroduction.vue"),
        meta: {
          title: "产品介绍",
        },
      },
      {
        path: "cooperation",
        name: "cooperation",
        component: () => import("../views/CooperationView.vue"),
        meta: {
          title: "商业合作",
        },
      },
      {
        path: "joinUs",
        name: "joinUs",
        component: () => import("../views/JoinUs.vue"),
        meta: {
          title: "诚聘英才",
        },
      },
      {
        path: "contactUs",
        name: "contactUs",
        component: () => import("../views/ContactUs.vue"),
        meta: {
          title: "联系我们",
        },
      },
    ],
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  // 根据路由设置当前菜单信息
  let currentMenu = to.name; // 假设路由的名称与菜单项相对应
  if (
    to.name == "casePresentation" ||
    to.name == "productIntroduction" ||
    to.name == "groupOverview"
  ) {
    store.dispatch("setCurrentMenu", [currentMenu, to.query.name]);
  } else {
    store.dispatch("setCurrentMenu", [currentMenu]);
  }
  let randomNum = Math.floor(Math.random() * 5);
  store.commit("setCurrentBannerIndex", randomNum);
  next();
});

export default router;
