<template>
  <div class="layout">
    <div
      class="nav_wrap"
      ref="header"
      :class="{ header_active: isActiveHeader }"
    >
      <Header />
    </div>
    <div class="content">
      <RouterView />
    </div>
    <div class="footer_wrap" ref="footer" :class="{ foot_active: isInView }">
      <Footer />
    </div>
  </div>
</template>
<script>
export default {
  name: "layoutView",
};
</script>
<script setup>
import { ref, reactive, computed, onMounted, onUnmounted, watch } from "vue";
import Header from "./header.vue";
import Footer from "./footer.vue";
import { useRoute } from "vue-router";
const route = useRoute();
const footer = ref(null);
const header = ref(null);
let isInView = ref(false);
let isActiveHeader = ref(false);

const isElementInView = (element, top) => {
  if (!element) return false;
  const rect = element.getBoundingClientRect();
  let isInHeight =
    Math.floor(rect.top) >= 0 &&
    Math.floor(rect.bottom) <= Math.floor(window.innerHeight);
  let isScrollFooter =
    Math.floor(rect.bottom) - Math.floor(window.innerHeight) <= window.scrollY;
  return isInHeight || isScrollFooter;
};

const scrollFn = (e) => {
  isInView.value = isElementInView(footer.value);
};

const handleScroll = scrollFn;

watch(
  () => route.name,
  (newRoute) => {
    // console.log(route.name);
    isInView.value = false;
    isActiveHeader.value = false;
    let time = setTimeout(() => {
      if (footer.value) {
        scrollFn();
      }
      if (header.value) {
        isActiveHeader.value = true;
      }
    }, 500);
  }
);

onMounted(() => {
  window.addEventListener("scroll", handleScroll);
  if (footer.value) {
    scrollFn();
  }
  if (header.value) {
    if (header.value) {
      isActiveHeader.value = true;
    }
  }
});

onUnmounted(() => {
  window.removeEventListener("scroll", handleScroll);
});
</script>
<style lang="less">
@import url(../../assets/css/animation.less);

.nav_wrap {
  &.header_active {
    .header_top_wrap {
      animation: top_to_bottom 1s, opacity_0_1 1s;
    }
    .company_name {
      .logo,
      .name {
        animation: left_to_right 1s, opacity_0_1 1s;
      }
      .contact {
        animation: right_to_left 1s, opacity_0_1 1s;
      }
    }
    .nav_list {
      animation: top_to_bottom 1s, opacity_0_1 1s;
    }
  }
}
.footer_wrap {
  &.foot_active {
    .footer {
      .footer_content {
        position: relative;
        top: 0;
        opacity: 1;
        height: auto;
      }
    }
  }
  .footer {
    min-height: 25rem;
    .footer_content {
      position: relative;
      top: 100px;
      transition: all 1s;
      opacity: 0;
      height: 0;
      overflow: hidden;
    }
  }
}
</style>
