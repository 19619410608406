import { createStore } from "vuex";

export default createStore({
  state: {
    currentMenu: null,
    bannerIndex: null,
    footTop: null,
  },
  getters: {},
  mutations: {
    setCurrentMenu(state, menu) {
      state.currentMenu = menu;
    },
    setCurrentBannerIndex(state, index) {
      state.bannerIndex = index;
    },
    setFootTop(state, top) {
      state.footTop = top;
    },
  },
  actions: {
    setCurrentMenu({ commit }, menu) {
      commit("setCurrentMenu", menu);
    },
  },
  modules: {},
});
