<template>
  <RouterView />
</template>

<style lang="less">
* {
  padding: 0;
  margin: 0;
}
html {
  font-size: 10px;
}
// // <=600
// @media screen and (max-width: 600px) {
//   html {
//    font-size: ;
//   }
// }
</style>
